<template>
    <div class="page-wrapper">
        <section class="hero_centre contact">
            <div class="title_wrap">
                <img
                    src="@/assets/img/icon-deux-maisons.svg"
                    class="bigger"
                    alt="Icon Maison Péladeau"
                    data-inview="fadeInUp"
                    data-delay="200"
                />
                <h1 class="title" data-inview="fadeInUp" data-delay="300"><span v-html="contentIsLoaded ? content.entete2.titre : ''"></span></h1>
                <h2 class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="contentIsLoaded ? content.entete2.texte : ''"></span>
                </h2>
            </div>
        </section>

        <section class="faq_wrap" v-if="pageIsLoaded">
            <div v-for="(item, i) in content.faq" :key="i">
                <div class="faq_title" :class="{ active: faq === i }" @click.prevent="showFaq(i)">
                    <p class="regular-text">{{ item.titre }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="Arrow right" />
                </div>
                <div class="faq_details" :class="{ active: faq === i }">
                    <p class="regular-text">
                        <span v-html="item.texte"></span>
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
// import Flickity from 'flickity'

export default {
    name: 'FAQ',

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
            script: [{
                type: 'application/ld+json',
                json: {
                    '@context': 'http://schema.org',
                    '@type': 'FAQPage',
                    mainEntity: this.faqContent
                }
            }]
        }
    },

    data() {
        return {
            // teamCarousel: null,
            faq: 0,
            faqContent: []
        }
    },

    methods: {
        showFaq(value) {
            if (this.faq === value) {
                this.faq = 0
            } else {
                this.faq = value
            }
        },
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_centre')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_centre').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_centre').classList.remove('remove_margin_top')
            }
        },
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.content.faq.forEach((item) => {
                        this.faqContent.push({
                            "@type": "Question",
                            "name": item.titre,
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": item.texte
                            }
                        })
                    })
                    setTimeout(() => {
                        // this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                        //     wrapAround: true,
                        //     imagesLoaded: true,
                        //     prevNextButtons: true,
                        //     pageDots: false,
                        // })

                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
